import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { MessagesContext } from "../context/MessagesContext";
import { NotificationContext } from "../context/NotificationContext";
import { UserContext } from "../context/UserContext";
import updateUserContext from "../helper/updateUserContext";
import { Translation } from "./Translation";

// dialog to clear the message history and create a new session
export default function ClearDialog({ open, setOpen }) {
  const { setMessages } = useContext(MessagesContext);
  const { notification, setNotification } = useContext(NotificationContext);
  const { user, setUser } = useContext(UserContext);

  const handleCancel = () => {
    setOpen(false);
  };

  // delete the current chat history and stop the tutorial
  const handleClear = () => {
    setMessages([]);
    updateUserContext(user, setUser, {
      session: Math.floor(Math.random() * 10000),
      startTutorial: false,
    });

    setNotification({
      ...notification,
      type: "info",
      show: true,
      message: Translation.ClearDialog.notification,
    });
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Translation.ClearDialog.header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Translation.ClearDialog.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear}>
            {Translation.ClearDialog.delete}
          </Button>
          <Button onClick={handleCancel} autoFocus>
            {Translation.ClearDialog.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
