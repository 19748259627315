import axios from "axios";
import { useContext, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Alert,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
} from "@mui/material";
import { UserContext } from "../context/UserContext";
import { ConfigurationContext } from "../context/ConfigurationContext";
import { useNavigate, useParams } from "react-router-dom";
import * as jose from "jose";
import { Translation } from "../components/Translation";
import StartImage from "../static/start.jpg";
import SecondStartImage from "../static/start2.png";
import ThirdStartImage from "../static/start3.png";
import FourthStartImage from "../static/start4.png";
import FivthStartImage from "../static/start5.png";

const theme = createTheme();

// select random start picture
const startPictures = [
  StartImage,
  SecondStartImage,
  ThirdStartImage,
  FourthStartImage,
  FivthStartImage,
];
const currentImage =
  startPictures[Math.floor(Math.random() * startPictures.length)];

// login page asking for username, password and department
export default function LoginPage() {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { configuration, setConfiguration } = useContext(ConfigurationContext);
  const navigate = useNavigate();
  const { key, business } = useParams();
  const [userKey, setUserKey] = useState(key);
  const [userBusiness, setUserBusiness] = useState(
    business !== undefined &&
      Translation.login.businessUnits.find(
        (unit) => unit.short === business
      ) !== undefined
      ? business
      : ""
  );

  // create a JWT token signed with the password/ key without any meaningfull content
  const createToken = async (key) => {
    const secret = new TextEncoder().encode(key);
    const alg = "HS256";

    return await new jose.SignJWT({ data: "request access" })
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .setExpirationTime("8h")
      .sign(secret);
  };

  // check if credentials are correct, store them and mark user as authenticated
  const login = async (key, business) => {
    createToken(key).then((token) => {
      axios
        .post(
          "/api/login",
          { business: business },
          {
            headers: {
              "Custom-Auth-Key": token,
            },
          }
        )
        .then((response) => {
          if (response.data !== undefined) {
            // store avaliable models
            setConfiguration({
              ...configuration,
              models: response.data.models,
              model: response.data.models.filter((e) => e.default === true)[0],
            });

            // store username and password for next requests
            setUser({
              ...user,
              id: response.data.id,
              authenticated: true,
              token: token,
              business:
                response.data.business !== undefined
                  ? response.data.business
                  : business,
              session: Math.floor(Math.random() * 10000),
              counter: 0,
            });

            // navigate to home screen
            navigate("/home");
          }
        })
        .catch((error) => {
          let message = Translation.login.error.technical;
          if (error.response !== undefined) {
            if (error.response.status === 401) {
              message = Translation.login.error.key;
            } else if (error.response.status === 423) {
              message = Translation.login.error.kbr;
            }
          }

          setLoading(false);
          setError(true);
          setErrorMessage(message);
        });
    });
  };

  // validate input parameters
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setError(false);

    if (data.get("security") === null) {
      setErrorMessage(Translation.login.validation.gdpr);
      setError(true);
    } else if (data.get("checked") === null) {
      setErrorMessage(Translation.login.validation.security);
      setError(true);
    } else if (data.get("key") === "") {
      setErrorMessage(Translation.login.validation.key);
      setError(true);
    } else if (data.get("business") === "") {
      setErrorMessage(Translation.login.validation.business);
      setError(true);
    } else {
      setLoading(true);
      login(userKey, userBusiness);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${currentImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    src="/logo.png"
                    sx={{ width: 45, height: 45 }}
                    alt={Translation.login.help.logo}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography component="h1" variant="h5">
                      {Translation.title}
                    </Typography>
                  }
                  secondary={Translation.login.poweredBy}
                />
              </ListItem>
            </List>
            {error === true ? (
              <Alert severity="error">{errorMessage}</Alert>
            ) : (
              ""
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="key"
                label={Translation.login.key}
                type="password"
                id="key"
                autoComplete="Schlüssel"
                onChange={(event) => setUserKey(event.target.value)}
                value={userKey}
                autoFocus
              />
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="business-label">
                  {Translation.login.business}
                </InputLabel>
                <Select
                  labelid="business-label"
                  fullWidth
                  name="business"
                  label={Translation.login.business}
                  id="business"
                  value={userBusiness}
                  onChange={(event) => setUserBusiness(event.target.value)}
                >
                  {Translation.login.businessUnits.map((unit) => {
                    return (
                      <MenuItem key={unit.short} value={unit.short}>
                        {unit.long}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl component="fieldset" variant="standard">
                <Typography variant="subtitle2" color="#bf1c1c" gutterBottom>
                  {Translation.login.disclaimer.title}
                </Typography>
                <Typography variant="body1" color="#bf1c1c" gutterBottom>
                  {Translation.login.disclaimer.text}
                </Typography>
              </FormControl>
              <FormControl component="fieldset" variant="standard">
                <Typography variant="subtitle2" gutterBottom>
                  {Translation.login.data.title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {Translation.login.data.text}
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch name="checked" required />}
                    label={Translation.login.data.confirm}
                  />
                </FormGroup>
              </FormControl>
              <FormControl component="fieldset" variant="standard">
                <Typography variant="subtitle2" gutterBottom>
                  {Translation.login.gdpr.title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {Translation.login.gdpr.text}
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch name="security" required />}
                    label={Translation.login.gdpr.confirm}
                  />
                </FormGroup>
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {Translation.login.button}
              </Button>
              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ mt: 8, mb: 4 }}
              >
                {"Copyright © DM "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
