import axios from "axios";
import { Translation } from "../components/Translation";

// send a question, if not empty, to the API
export default function submitQuestion(
  question,
  messages,
  setMessages,
  user,
  setUser,
  notification,
  setNotification,
  configuration,
  navigate,
  setQuestion,
  clearQuestion = true,
  promptId = 0
) {
  if (question === "" || question.trim() === "") {
    setNotification({
      ...notification,
      type: "error",
      show: true,
      message: Translation.submitQuestion.empty,
    });
  } else {
    setNotification({
      ...notification,
      loading: true,
    });

    axios
      .post(
        "/api/ask",
        {
          id: user.id,
          session: user.session,
          business: user.business,
          question: question,
          history: messages,
          model: configuration.model.name,
          storePrompts: configuration.storePrompts,
          promptId: promptId,
        },
        {
          headers: {
            "Custom-Auth-Key": user.token,
          },
        }
      )
      .then((response) => {
        if (response.data !== undefined) {
          // store the question as entry and the answer including the current question-answer history with the total tokens
          setMessages([
            ...messages,
            {
              id: response.data.id,
              answer: response.data.answer,
              question: question,
              totalTokens: response.data.usage.total_tokens,
            },
          ]);

          // increase number of asked questions only if tutorial is not active and mark if last prompt was created via prompt generator
          setUser({
            ...user,
            counter:
              user.startTutorial === false ? user.counter + 1 : user.counter,
            promptGeneratorUsed: promptId !== 0 ? true : false,
          });

          // clear data
          setNotification({
            ...notification,
            loading: false,
          });

          if (clearQuestion === true) {
            setQuestion("");
          }
        }
      })
      .catch((error) => {
        // don't clear message in case of error
        let errorMessage = Translation.submitQuestion.error;
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            // force login
            setUser({
              ...user,
              authenticated: false,
              user: null,
              password: null,
              key: null,
            });
            navigate("/login");
          } else if (error.response.status === 429) {
            // backend has request rate limit of Azure OpenAI Service reached
            errorMessage = Translation.submitQuestion.rateLimit;
          } else if (error.response.status === 406) {
            // Azure OpenAI Service model has token limit reached
            errorMessage = Translation.submitQuestion.tokenLimit;
          } else if (error.response.status === 503) {
            // Azure OpenAI API is unavailable
            errorMessage = Translation.submitQuestion.openAiApiUnavailable;
          }
        }

        setNotification({
          ...notification,
          type: "error",
          show: true,
          message: errorMessage,
          loading: false,
        });
      });
  }
}
