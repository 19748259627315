import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { ProtectedRoute } from "./components/ProtectedRoute";
import BasicPage from "./pages/BasicPage";

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const Paths = () => (
  <Routes>
    <Route
      exact
      path="/home"
      element={
        <ProtectedRoute>
          <BasicPage />
        </ProtectedRoute>
      }
    />
    <Route exact path="/login/:key/:business" element={<LoginPage />} />
    <Route exact path="/login/:key" element={<LoginPage />} />
    <Route path="*" element={<LoginPage />} />
  </Routes>
);

export default Paths;
