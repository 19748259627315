import { IconButton, TextField, Toolbar, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { MessagesContext } from "../context/MessagesContext";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../context/NotificationContext";
import SendIcon from "@mui/icons-material/Send";
import { ConfigurationContext } from "../context/ConfigurationContext";
import { blue75, lightGrey } from "./Color";
import submitQuestion from "../helper/submitQuestion";
import { Translation } from "./Translation";
import { QuestionContext } from "../context/QuestionContext";

// search box to enter question and send it to the AI model
export default function Search() {
  const { question, setQuestion } = useContext(QuestionContext);
  const { messages, setMessages } = useContext(MessagesContext);
  const { user, setUser } = useContext(UserContext);
  const { notification, setNotification } = useContext(NotificationContext);
  const { configuration } = useContext(ConfigurationContext);
  const navigate = useNavigate();

  // send the string when Enter is pressed
  const handleChange = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      submitQuestion(
        question,
        messages,
        setMessages,
        user,
        setUser,
        notification,
        setNotification,
        configuration,
        navigate,
        setQuestion,
        true
      );
    }
  };

  return (
    <>
      <Toolbar sx={{ backgroundColor: lightGrey }}>
        <TextField
          id="question"
          name="question"
          label={Translation.search.field}
          aria-label={Translation.search.field}
          variant="standard"
          value={question}
          onChange={(event) => setQuestion(event.target.value)}
          onKeyUp={(event) => handleChange(event)}
          fullWidth
          autoFocus
          multiline
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label={Translation.search.button}
          onClick={() => {
            submitQuestion(
              question,
              messages,
              setMessages,
              user,
              setUser,
              notification,
              setNotification,
              configuration,
              navigate,
              setQuestion,
              true
            );
          }}
        >
          <SendIcon sx={{ color: blue75 }} />
        </IconButton>
      </Toolbar>
    </>
  );
}
