import { Grid, IconButton, Tooltip } from "@mui/material";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../context/NotificationContext";
import ReactMarkdown from "react-markdown";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { blue75, darkBlue } from "./Color";
import { Translation } from "./Translation";
import remarkGfm from "remark-gfm";

// configure default CSS values
const defaultBorderRadius = "25px";
const defaultMargin = "25px";
const defaultSize = 11;

// show a question and the corresponding answer
export default function Result({ data }) {
  const { user, setUser } = useContext(UserContext);
  const { notification, setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  // send data and rating to API
  const sendRating = (data, rating) => {
    axios
      .post(
        "/api/rate",
        {
          id: data.id,
          answer: data.answer,
          question: data.question,
          rating: rating,
        },
        {
          headers: {
            "Custom-Auth-Key": user.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setNotification({
            ...notification,
            type: "success",
            show: true,
            message: Translation.result.success,
          });
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            // force login
            setUser({
              ...user,
              authenticated: false,
              user: null,
              password: null,
              key: null,
            });
            navigate("/login");
          }
        }
      });
  };

  // copy to clipboard and show notification via toast
  const copyToClipboard = (answer, type) => {
    navigator.clipboard.writeText(answer);
    setNotification({
      ...notification,
      type: "info",
      show: true,
      message:
        type === "code"
          ? Translation.result.copy.code
          : Translation.result.copy.text,
    });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Grid item xs={defaultSize} md={defaultSize} lg={defaultSize}>
          <Card
            sx={{
              mt: defaultMargin,
              borderRadius: defaultBorderRadius,
            }}
          >
            <CardHeader
              sx={{
                bgcolor: blue75,
                color: "white",
                pt: "3px",
                pb: "3px",
              }}
              avatar={<QuestionMarkIcon />}
              title={
                <>
                  <Typography variant="h6">
                    {Translation.result.question}
                  </Typography>
                </>
              }
            />
            <CardContent sx={{ mb: 0, pb: 0 }}>{data.question}</CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Grid item xs={defaultSize} md={defaultSize} lg={defaultSize}>
          <Card
            sx={{
              mt: defaultMargin,
              borderRadius: defaultBorderRadius,
            }}
          >
            <CardHeader
              sx={{
                bgcolor: darkBlue,
                color: "white",
                pt: "3px",
                pb: "3px",
              }}
              avatar={<QuestionAnswerIcon />}
              title={
                <>
                  <Typography variant="h6">
                    {Translation.result.answer}
                  </Typography>
                </>
              }
            />

            <CardContent sx={{ mt: 0, pt: 0, pb: 0 }}>
              <ReactMarkdown
                children={data.answer}
                remarkPlugins={[remarkGfm]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <>
                        <SyntaxHighlighter
                          {...props}
                          children={String(children).replace(/\n$/, "")}
                          language={match[1]}
                          PreTag="div"
                        />
                        <Tooltip title={Translation.result.copy.title}>
                          <IconButton
                            size="small"
                            onClick={() =>
                              copyToClipboard(
                                String(children).replace(/\n$/, ""),
                                "code"
                              )
                            }
                          >
                            <ContentPasteIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <code {...props} className={className}>
                        {children}
                      </code>
                    );
                  },
                }}
              />
            </CardContent>
            <CardActions disableSpacing>
              <Tooltip title={Translation.result.feedback.negativ}>
                <IconButton size="small" onClick={() => sendRating(data, -1)}>
                  <ThumbDownAltIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title={Translation.result.feedback.positiv}>
                <IconButton size="small" onClick={() => sendRating(data, 1)}>
                  <ThumbUpAltIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title={Translation.result.copy.action}>
                <IconButton
                  size="small"
                  onClick={() => copyToClipboard(data.answer)}
                >
                  <ContentPasteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
