import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { Translation } from "./Translation";
import { blue75 } from "./Color";
import updateUserContext from "../helper/updateUserContext";
import { NotificationContext } from "../context/NotificationContext";
import { ConfigurationContext } from "../context/ConfigurationContext";
import { useNavigate } from "react-router-dom";
import { MessagesContext } from "../context/MessagesContext";
import submitQuestion from "../helper/submitQuestion";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import RepeatIcon from "@mui/icons-material/Repeat";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import axios from "axios";

// shwo a step by step guide
export default function Tutorial() {
  const { user, setUser } = useContext(UserContext);
  const { messages, setMessages } = useContext(MessagesContext);
  const { notification, setNotification } = useContext(NotificationContext);
  const { configuration } = useContext(ConfigurationContext);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(user.currentTutorialStep);

  // go to next, previous or first step
  const handleStep = (nextStep) => {
    setActiveStep(nextStep);
    updateUserContext(user, setUser, { currentTutorialStep: nextStep });
  };

  // stop the tutorial
  const stopTutorial = () => {
    updateUserContext(user, setUser, { startTutorial: false });
  };

  // send the feedback to the backend
  const giveTutorialFeedback = (feedback) => {
    axios
      .post(
        "/api/tutorial",
        {
          id: user.id,
          business: user.business,
          feedback: feedback,
        },
        {
          headers: {
            "Custom-Auth-Key": user.token,
          },
        }
      )
      .then(() => {
        // store feedback for current session and via local storage
        updateUserContext(user, setUser, { feedbackTutorial: true });

        setNotification({
          ...notification,
          type: "success",
          show: true,
          message: Translation.tutorial.rating.success,
        });
      })
      .catch(() => {
        setNotification({
          ...notification,
          type: "error",
          show: true,
          message: Translation.tutorial.rating.error,
        });
      });
  };

  return (
    <>
      <Card sx={{ mt: "25px", position: "sticky", top: "5rem" }}>
        <CardHeader
          title={Translation.tutorial.title}
          action={
            <IconButton onClick={() => stopTutorial()}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {Translation.tutorial.steps.map((step, index) => (
                <Step key={`step-${step.number}-${index}`}>
                  <StepLabel>{step.title}</StepLabel>
                  <StepContent>
                    <Typography>{step.content}</Typography>
                    {step.examples.length > 0 ? (
                      <>
                        <Typography variant="subtitle2" gutterBottom>
                          {step.examples.length > 1 ? (
                            <>{Translation.tutorial.example}</>
                          ) : (
                            <>{Translation.tutorial.examples}</>
                          )}
                        </Typography>
                        <Typography>
                          {step.examples.map((example, exampleIndex) => (
                            <>
                              {example}
                              <Tooltip
                                key={`example-${exampleIndex}`}
                                title={Translation.tutorial.tooltip}
                              >
                                <IconButton
                                  type="button"
                                  aria-label={Translation.tutorial.tooltip}
                                  size="small"
                                  onClick={() =>
                                    submitQuestion(
                                      example,
                                      messages,
                                      setMessages,
                                      user,
                                      setUser,
                                      notification,
                                      setNotification,
                                      configuration,
                                      navigate,
                                      null,
                                      false
                                    )
                                  }
                                >
                                  <SendIcon
                                    sx={{
                                      color: blue75,
                                      width: "0.5em",
                                      height: "0.5em",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <br />
                            </>
                          ))}
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}

                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={() => handleStep(activeStep + 1)}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {step.last === true ? (
                            <>{Translation.tutorial.finish}</>
                          ) : (
                            <>{Translation.tutorial.next}</>
                          )}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={() => handleStep(activeStep - 1)}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {Translation.tutorial.back}
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === Translation.tutorial.steps.length && (
              <Paper square elevation={0} sx={{ pt: 3, pl: 3 }}>
                <Typography>{Translation.tutorial.done}</Typography>
                {user.feedbackTutorial === false ? (
                  <>
                    <Button
                      onClick={() => giveTutorialFeedback(1)}
                      variant="text"
                      aria-label={Translation.tutorial.positive.help}
                      startIcon={<ThumbUpAltIcon />}
                    >
                      {Translation.tutorial.positive.button}
                    </Button>
                    <Button
                      onClick={() => giveTutorialFeedback(-1)}
                      variant="text"
                      aria-label={Translation.tutorial.negativ.help}
                      startIcon={<ThumbDownAltIcon />}
                    >
                      {Translation.tutorial.negativ.button}
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <Button
                  size="small"
                  href={Translation.tutorial.feedback.target}
                  target="_blank"
                  variant="text"
                  aria-label={Translation.tutorial.feedback.help}
                  startIcon={<MailOutlineIcon />}
                >
                  {Translation.tutorial.feedback.button}
                </Button>
                <Button
                  onClick={() => handleStep(0)}
                  startIcon={<RepeatIcon />}
                >
                  {Translation.tutorial.reset}
                </Button>
              </Paper>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
